import React, { useEffect, useState } from "react";
import Mapa from "../components/UI/components/Mapa";
import useProductos from "../hooks/useProductos";
import { useNavigate } from "react-router-dom";
import { RiCheckFill,RiAlertLine  } from "react-icons/ri";

const GetLocation = () => {
    const {
        latitude,
        longitude,
        getLocation,
        guardarUbicacion,
        userUbication,
        handleMostrarCambioUbicacion,
        handleLatitude,
        handleLongitude,
    } = useProductos();
    const [step, setStep] = useState(1);
    const [currentCoords, setCurrentCoords] = useState({
        lat: latitude,
        lng: longitude,
    });

    const [direccion, setDireccion] = useState("");
    const [direccionGoogle, setDireccionGoogle] = useState("");
    const [referencia, setReferencia] = useState("");
    const [telefono, setTelefono] = useState("");
    const [mostrarMensajeUbicacion, setmostrarMensajeUbicacion] =
        useState(true);
    const [countdown, setCountdown] = useState(6);
    // Estados para errores de validación
    const [errorDireccion, setErrorDireccion] = useState("");
    const [errorReferencia, setErrorReferencia] = useState("");
    const [errorTelefono, setErrorTelefono] = useState("");

    useEffect(() => {
        if (step === 2 && !latitude && !longitude) {
            getLocation();
        }
    }, [step, latitude, longitude, getLocation]);

    const navigate = useNavigate();

    const setUbicacionCliente = (newCoords) => {
        setCurrentCoords(newCoords);
    };

    async function obtenerDireccion(latitud, longitud) {
        const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitud},${longitud}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.status === "OK" && data.results.length > 0) {
                let direccion = data.results[0].formatted_address;
                const partesDireccion = direccion.split(", ");
                direccion = partesDireccion[0];
                return direccion;
            } else {
                throw new Error("No se pudo obtener la dirección");
            }
        } catch (error) {
            console.error("Error al obtener la dirección:", error);
            return null;
        }
    }

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         //setmostrarMensajeUbicacion(false);
    //     }, 5000); // 5000 milisegundos = 5 segundos

    //     // Limpieza del timeout cuando el componente se desmonte
    //     return () => clearTimeout(timer);
    // }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setmostrarMensajeUbicacion(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (step === 3) {
            obtenerDireccion(currentCoords.lat, currentCoords.lng)
                .then((direccion) => {
                    setDireccion(direccion);
                    setDireccionGoogle(direccion);
                })
                .catch((error) => {
                    console.error("Error al obtener la dirección:", error);
                });
        }
    }, [step, currentCoords]);

    useEffect(() => {
        if (userUbication && !direccion) {
            setDireccion(userUbication.direccion);
            setReferencia(userUbication.referencia);
            setTelefono(userUbication.telefono);
        }
    }, [userUbication, direccion]);

    const validarFormulario = () => {
        let isValid = true;

        if (!direccion.trim()) {
            setErrorDireccion("La dirección es obligatoria");
            isValid = false;
        } else {
            setErrorDireccion("");
        }

        if (!referencia || !referencia.trim()) {
            setErrorReferencia("Debes proporcionarnos una referencia clara");
            isValid = false;
        } else {
            setErrorReferencia("");
        }

        const telefonoRegex = /^[0-9]{9}$/;
        if (!telefonoRegex.test(telefono)) {
            setErrorTelefono(
                "El teléfono debe ser un número de 9 dígitos válido"
            );
            isValid = false;
        } else {
            setErrorTelefono("");
        }

        return isValid;
    };

    const handleGuardarUbicacion = () => {
        if (!validarFormulario()) return;

        guardarUbicacion(
            currentCoords.lat,
            currentCoords.lng,
            direccion,
            direccionGoogle,
            referencia,
            telefono
        );

        navigate("/");
        handleMostrarCambioUbicacion(true);
        localStorage.setItem("latitude", currentCoords.lat.toString());
        localStorage.setItem("longitude", currentCoords.lng.toString());
        handleLatitude(currentCoords.lat);
        handleLongitude(currentCoords.lng);
    };

    return (
        <div className="container-obtener-ubicacion">
            {step === 1 && (
                <div className="container-mensaje-ubicacion">
                    <h1>
                        Hola!!, para empezar, por favor acepta el permiso de
                        ubicación
                    </h1>
                    <h4>
                        Esto nos sirve para poder entregar tus pedidos de forma
                        rápida y precisa
                    </h4>
                    <button
                        className="button-entendido-ubicacion"
                        onClick={() => setStep(2)}
                    >
                        Entendido
                    </button>
                </div>
            )}
            {step === 2 && (
                <>
                    {latitude && longitude && (
                        <div className="mapa">
                            <Mapa
                                coordenadas={{
                                    lat: latitude,
                                    lng: longitude,
                                }}
                                onMarkerPositionChange={setUbicacionCliente}
                            />
                        </div>
                    )}
                    <div>
                        {mostrarMensajeUbicacion && (
                            <p className="mensaje-ubicacion-cliente">
                                <RiAlertLine className="icon-alert" />
                                Mueve el mapa hasta que el marcador esté en tu
                                ubicacion. Esta ubicacion sera donde
                                entregaremos tu pedido ({countdown}s)
                            </p>
                        )}
                        <button
                            type="button"
                            onClick={() => setStep(3)}
                            className="button-ubicacion"
                        >
                            <RiCheckFill className="icon-check" /> Listo
                        </button>
                    </div>
                </>
            )}
            {step === 3 && (
                <div className="container-mensaje-ubicacion">
                    <h2>Ya casi estamos, solo necesitamos estos datos</h2>
                    <p>
                        Recuerda poner la informacion precisa de donde se
                        entregara el producto, el motorizado se dirigira a esta
                        ubicacion{" "}
                    </p>
                    <div>
                        <h4>Dirección</h4>
                        <input
                            type="text"
                            className="input-direccion"
                            placeholder="Ejm: Av. luzuriaga 123"
                            value={direccion}
                            onChange={(e) => setDireccion(e.target.value)}
                        />
                        {errorDireccion && (
                            <p className="errorCampo">{errorDireccion}</p>
                        )}
                    </div>
                    <div>
                        <h4>
                            Referencia <p>Para ubicarnos más rapido</p>
                        </h4>

                        <input
                            type="text"
                            className="input-referencia"
                            placeholder="Ejm: a espaldas del colegio tomas valle"
                            value={referencia}
                            onChange={(e) => setReferencia(e.target.value)}
                        />
                        {errorReferencia && (
                            <p className="errorCampo">{errorReferencia}</p>
                        )}
                    </div>
                    <div>
                        <h4>
                            Teléfono{" "}
                            <p>
                                A quien nos contactamos para la entrega del
                                producto
                            </p>{" "}
                        </h4>
                        <input
                            type="tel"
                            className="input-telefono"
                            placeholder="Ejm: 987654321"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                        />
                        {errorTelefono && (
                            <p className="errorCampo">{errorTelefono}</p>
                        )}
                    </div>
                    <button
                        type="button"
                        className="button-guardar-direccion"
                        onClick={handleGuardarUbicacion}
                    >
                        Continuar
                    </button>
                </div>
            )}
        </div>
    );
};

export default GetLocation;
