import React, { useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Mapa = ({ coordenadas, onMarkerPositionChange }) => {
  const [center, setCenter] = useState(coordenadas);
  const mapRef = useRef(null);

  const handleCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = {
        lat: mapRef.current.getCenter().lat(),
        lng: mapRef.current.getCenter().lng(),
      };

      if (newCenter.lat !== center.lat || newCenter.lng !== center.lng) {
        setCenter(newCenter);
        onMarkerPositionChange(newCenter);
      }
    }
  };

  

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={17}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          gestureHandling: 'greedy',
        }}
        onLoad={(map) => (mapRef.current = map)}
        onCenterChanged={handleCenterChanged}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Mapa;
